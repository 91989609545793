<template>
  <Sect id="ProjectTechnical">
    <SectionOpen title="Saulės moduliai">
      <div v-for="detailsModule in detailsModules" :key="detailsModule.id" class="detailsModule">
        <div class="details nowrap">
          <div
            v-for="(detail, index) in detailsModule.details"
            :key="index"
            class="detail"
            :xl="2"
            :xs="6">
            <div class="content">
              <div class="label">
                {{ detail.label }}
              </div>
              <div class="value">
                {{ detail.value }}
              </div>
            </div>
          </div>
          <div class="textSection">
            <!-- <ul>
              <li v-for="(point, index) in detailsModule.points" :key="index">
                <b>{{ point.highlight }}</b> {{ point.text || "" }}
              </li>
            </ul> -->
            <Markdown :hasStyles="false" :markdown="detailsModule.description" />
          </div>
        </div>
        <div class="moreInfo">
          <a :href="detailsModule.moreInfoSrc" target="_blank">
            <Btn class="minimalistic" styleType="naked">
              Daugiau informacijos
            </Btn>
          </a>
        </div>
      </div>
    </SectionOpen>
    <SectionOpen title="Įtampos keitikliai">
      <div v-for="detailsSolarInverter in detailsSolarInverters" :key="detailsSolarInverter.id" class="detailsModule">
        <div class="details nowrap">
          <div
            v-for="(detail, index) in detailsSolarInverter.details"
            :key="index"
            class="detail">
            <div class="content">
              <div class="label">
                {{ detail.label }}
              </div>
              <div class="value">
                {{ detail.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="textSection">
          <!-- <ul>
            <li v-for="(point, index) in project.inverter.points" :key="index">
              <b>{{ point.highlight }}</b> {{ point.text || "" }}
            </li>
          </ul> -->
          <Markdown :hasStyles="true" :markdown="detailsSolarInverter.description" />
        </div>
        <div class="moreInfo">
          <a :href="detailsSolarInverter.moreInfoSrc" target="_blank">
            <Btn class="minimalistic" styleType="naked">
              Daugiau informacijos
            </Btn>
          </a>
        </div>
      </div>
    </SectionOpen>
    <SectionOpen v-if="project.mountingManufacturer" title="Montavimo konstrukcijos">
      <div class="details nowrap">
        <div
          v-for="(detail, index) in detailsConstruction"
          :key="index"
          class="detail construction">
          <div class="content">
            <div class="label">
              {{ detail.label }}
            </div>
            <div class="value">
              {{ detail.value }}
            </div>
          </div>
        </div>
      </div>
    </SectionOpen>
    <SectionOpen title="Aptarnavimas">
      <div class="textSection maintenance">
        <h3>Metinė priežiūros kaina su PVM – {{ project.maintenanceAnnual }} Eur/kW.</h3>
        <h4>Metinę priežiūrą sudaro:</h4>
        <ul>
          <li>Įrenginių techninė priežiūra;</li>
          <li>Elektrinės garantinių gedimų šalinimas;</li>
          <li>Įrenginių apsauga;</li>
          <li>Įrenginių valymas;</li>
          <li>Įrenginių draudimas;</li>
          <li>Įrenginių utilizavimas po 25 metų.</li>
        </ul>
      </div>
    </SectionOpen>
  </Sect>
</template>

<script>
import SectionOpen from './SectionOpen'
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  components: {
    SectionOpen,
    Markdown,
  },
  props: {
    project: Object,
  },
  computed: {
    detailsModules() {
      const { modules } = this.project

      if (!modules) return []

      return modules.map(module => ({

        details: [
          {
            label: 'Gamintojas',
            value: module.module.manufacturer,
          },
          {
            label: 'Modelis',
            value: module.module.model,
          },
          {
            label: 'Galingumas',
            value: `${module.module.capacity} W`,
          },
          {
            label: 'Kiekis',
            value: module.count,
          },
          {
            label: 'Garantija',
            value: `${module.module.warranty} metų`,
          },
        ],
        description: module.module.description,
        moreInfoSrc: module.module.moreInfoSrc,
      }))
    },
    detailsSolarInverters() {
      const { inverters } = this.project

      if (!inverters) return []

      return inverters.map(inverter => ({
        details: [
          {
            label: 'Gamintojas',
            value: inverter.inverter.manufacturer,
          },
          {
            label: 'Modelis',
            value: inverter.inverter.model,
          },
          {
            label: 'Galingumas',
            value: `${inverter.inverter.capacity} kW`,
          },
          {
            label: 'Kiekis',
            value: inverter.count,
          },
          {
            label: 'Garantija',
            value: `${inverter.inverter.warranty} metų`,
          },
        ],
        description: inverter.inverter.description,
        moreInfoSrc: inverter.inverter.moreInfoSrc,
      }))
    },
    detailsConstruction() {
      if (!this.project.mountingManufacturer) return []

      return [
        {
          label: 'Gamintojas',
          value: this.project.mountingManufacturer,
        },
        {
          label: 'Garantija',
          value: this.project.mountingWarranty,
        },
      ]
    },
  },
}
</script>

<style lang="scss">
#ProjectTechnical {
  // padding: 3rem 0 1rem;
  padding-top: 6rem;
  position: relative;

  .detailsModule {
    &:not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    // flex-wrap: wrap;
    // justify-content: space-between;
    width: 100%;
    @include xl {
      border-top: 2px solid $black;
      flex-direction: row;
    }

    .detail {
      align-items: center;
      border-bottom: solid 2px $black;
      display: flex;
      // flex: 1 1 0;
      padding: 1rem 0 1rem 1rem;
      white-space: normal;
      @include xl {
        border-bottom: unset;
        border-left: solid 2px $black;
        width: 20%;

        &.construction {
          width: 50%;
        }
        // &:nth-of-type(odd) {
        //   border-left: solid 2px $black;
        //   padding-left: 1rem;
        // }

        &:first-of-type {
          border-left: none;
          padding-left: 0;
        }
      }

      .label {
        font-size: 14px;
        margin-bottom: 0.5rem;
        opacity: 0.5;
      }

      .value {
        font-size: 1.125rem;
        font-weight: $bold;
      }

      // &:nth-of-type(odd) {
      //   border-left: none;
      //   padding: 1rem 1rem 1rem 0;
      // }
    }
  }

  .textSection {
    line-height: $lh * 1.5;
    margin: 3rem 0 0 1rem;
    text-align: left;
    width: 100%;

    &.maintenance {
      margin: 1.5rem 0 0 1rem;

      h3 {
        margin-bottom: 1rem;
      }

      h4 {
        color: $text-color;
        font-size: 1rem;
        font-weight: $bold;
      }

      ul {
        font-weight: $bold;
        margin-left: 1rem;
      }
    }
  }

  .moreInfo {
    margin-top: 3rem;
    width: 18rem;
  }
}
</style>
