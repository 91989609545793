<template>
  <div class="Timeline">
    <CardTimeline
      v-for="post in timeline"
      :key="post.id"
      :date="post.dateAt"
      :icon="post.icon"
      :prefix="post.prefix"
      :text="post.text"
      :title="post.title" />
  </div>
</template>

<script>
import CardTimeline from './CardTimeline'

export default {
  components: { CardTimeline },
  props: {
    timeline: Array,
  },
}
</script>

<style lang="scss">
.Timeline {
  position: relative;

  &::before {
    background: $main;
    content: '';
    height: 100%;
    left: 18px;
    position: absolute;
    top: 0;
    width: 4px;
    @include md {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
