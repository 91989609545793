<template>
  <Sect id="ProjectSpecifications">
    <Wrap class="md">
      <div class="toggleContainer">
        <div class="toggle">
          <span
            v-for="option in toggleOptions"
            :key="option.id"
            :class="{ active: toggledId === option.id }"
            role="button"
            @click="toggledId = option.id">
            {{ option.label }}
          </span>
        </div>
      </div>
      <div class="toggledContainer">
        <SelfProjectProgress v-if="toggledId === 1" :projectId="project.id" />
        <ProjectTechnical v-if="toggledId === 2" :project="project" />
        <div v-if="toggledId === 3" class="placementContainer">
          <Pic role="button" :src="project.placementImageUrl" @click.native="isPlacementModalOpen = true" />
        </div>
        <div v-if="toggledId === 4" class="contractPreviewContainer">
          <VuePdf
            class="pdf-thumbnail"
            role="button"
            :src="project.contractPreviewUrl"
            @click.native="isTemplateModalOpen = true" />
          <div class="over">
            Peržiūra
          </div>
        </div>
      </div>
      <ModalCont v-if="isPlacementModalOpen" class="placementModal" @close="close">
        <Pic :src="project.placementImageUrl" />
      </ModalCont>
      <ModalCont v-if="isTemplateModalOpen" class="templateModal" @close="close">
        <VuePdfEmbed
          :source="project.contractPreviewUrl" />
      </ModalCont>
    </Wrap>
  </Sect>
</template>

<script>
import ProjectTechnical from './ProjectTechnical'
import SelfProjectProgress from 'views/SelfProjectProgress'
import VuePdf from 'vue-pdf'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { getters } from 'views/utils'

export default {
  components: {
    ProjectTechnical,
    SelfProjectProgress,
    VuePdfEmbed,
    VuePdf,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      isPlacementModalOpen: false,
      isTemplateModalOpen: false,
      toggledId: 2,
    }
  },
  computed: {
    toggleOptions() {
      return [
        {
          id: 1,
          label: 'PLANUOJAMA PROJEKTO EIGA',
        },
        {
          id: 2,
          label: 'TECHNINĖ INFORMACIJA',
        },
        {
          id: 3,
          label: 'MODULIŲ IŠDĖSTYMAS',
        },

        ...this.isAuth && this.project.contractPreviewUrl
          ? [
            {
              id: 4,
              label: 'SUTARTIES ŠABLONAS',
            },
          ]
          : [],
      ]
    },
    ...getters(),
  },
  methods: {
    close() {
      this.isPlacementModalOpen = false
      this.isTemplateModalOpen = false
    },
  },
}
</script>

<style lang="scss">
#ProjectSpecifications {
  background: $white;
  padding-top: 6rem;
  position: relative;

  .toggleContainer {
    display: flex;
    justify-content: center;
  }

  .toggledContainer {
    min-height: 30rem;
  }

  .toggle {
    display: flex;
    flex-direction: column;
    font-size: $h3;
    font-weight: $bold;
    padding: 0.5rem 1rem;
    text-align: center;
    width: 45rem;
    @include lg {
      border: 2px solid $black;
      border-radius: $radius-round;
      flex-direction: row;
      font-size: unset;
      justify-content: space-around;
    }

    > span {
      border-radius: $radius-round;
      padding: 0.5rem 1rem;

      &.active {
        background: $yellow;
      }
    }
  }

  .placementModal {
    img {
      width: 100%;
    }
  }

  .templateModal {
    .Modal {
      height: 80vh;

      .vue-pdf-embed {
        background: $grey;

        .vue-pdf-embed__page {
          margin: 0.2rem 0.4rem;
        }
      }
    }
  }

  .placementContainer {
    display: flex;
    justify-content: center;
    margin: 4rem 0;

    > a {
      border-bottom: unset;
      cursor: pointer;
    }

    img {
      height: 20rem;
      width: auto;
    }
  }

  .contractPreviewContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 4rem 0;
    position: relative;

    .over {
      align-items: center;
      color: $white;
      display: flex;
      font-size: $h2;
      font-weight: $bold;
      height: 100%;
      justify-content: center;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform: rotate(-30deg);
      width: 100%;
    }

    .pdf-thumbnail {
      filter: blur(1px) brightness(0.7);
      max-height: fit-content;

      > canvas {
        box-shadow: $shadow-md;
        height: 5rem;
      }

      &:hover {
        filter: blur(1px) brightness(0.6);
      }
    }

  }

}
</style>
