<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionAboutProject">
    <Wrap class="md">
      <h2>Apie saulės parką</h2>
      <Row class="points">
        <Column
          v-for="(card, index) in cards"
          :key="index"
          class="point"
          :lg="3"
          :xs="6">
          <Icon :name="card.icon" />
          <div class="label">
            {{ card.label }}
          </div>
          <div class="value">
            <b>{{ card.value }} {{ card.suffix || '' }}</b>
          </div>
        </Column>
      </Row>
      <div class="textContainer">
        <Markdown :hasStyles="true" :markdown="project.description" />
      </div>
    </Wrap>
  </Sect>
</template>

<script>
import { getUniqueArray } from 'views/utils'
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  components: { Markdown },
  props: {
    project: Object,
  },
  computed: {
    cards() {
      return [
        {
          icon: 'area-arrows',
          label: 'Plotas',
          value: this.project.area,
          suffix: 'ha',
        },
        {
          icon: 'solar-module',
          label: 'Saulės moduliai',
          value: this.projectModulesManufacturers,
        },
        {
          icon: 'gear-bolt',
          label: this.isNotBuilt ? 'Planuojama metinė generacija' : 'Metinė generacija',
          value: this.project.yieldAnnual,
          suffix: 'kWh',
        },
        {
          icon: 'solar-modules',
          label: 'Modulių kiekis',
          value: this.projectModulesCountTotal,
        },
      ]
    },
    isNotBuilt() {
      return this.project.status !== 'BUILT'
    },
    projectModulesManufacturers() {
      return this.project.modules
        ? getUniqueArray(this.project.modules.map(module => module.module.manufacturer))
          .join(', ')
        : ''
    },
    projectModulesCountTotal() {
      return this.project.modules.reduce((totalCount, module) => totalCount + module.count, 0)
    },
  },
}
</script>

<style lang="scss">
#SectionAboutProject {
  background: $white;
  padding-bottom: 0;

  h2 {
    @include dotAbove(1.5rem);
    margin: 3rem 0;
  }

  .points {
    margin: 4rem 0;
    @include md {
      gap: unset;
    }

    .point {
      margin-bottom: 4rem;
      text-align: center;

      .value {
        line-height: 2;
      }
    }
  }

  .textContainer {
    margin-top: 3rem;

    p {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  h3 {
    font-weight: $bold;
  }

  .Icon {
    color: $blue;
    font-size: 6rem;
  }

}
</style>
