<template>
  <div class="CardTimeline-block">
    <div class="CardTimeline-block-icon">
      <!-- <Icon :name="icon || 'newspaper'" /> -->
    </div>
    <div class="CardTimeline">
      <div class="title">
        {{ title }}
      </div>
      <div class="CardTimeline-date">
        {{ prefix || '' }} {{ date.getFullYear() }} m. {{ date | date(false) }}
      </div>
    </div>
    <!-- <CardFancy class="CardTimeline" :class="position" :title="title">
      <div class="CardTimeline-date">
        {{ prefix || '' }} {{ date.getFullYear() }} m. {{ date | date(false) }}
      </div>
      <pre v-if="text" class="CardTimeline-text">{{ text }}</pre>
    </CardFancy> -->
  </div>
</template>

<script>
// import CardFancy from './CardFancy'

export default {
  // components: { CardFancy },
  props: {
    date: Date,
    icon: {
      type: String,
      default: 'newspaper',
    },
    prefix: String,
    text: String,
    title: String,
  },
  computed: {
    position() {
      return this.index % 2
        ? 'left'
        : 'right'
    },
  },
}
</script>

<style lang="scss">
.CardTimeline-block {
  display: flex;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  // @include md {
  //   &:nth-child(even) {
  //     flex-direction: row-reverse;
  //   }
  // }

  &:last-child {
    margin-bottom: 0;
  }
}

.CardTimeline-block-icon {
  align-items: center;
  background: $blue 0% 0% no-repeat padding-box;
  border-radius: 50%;
  box-shadow: $shadow-md;
  display: flex;
  flex-shrink: 0;
  height: 1rem;
  justify-content: center;
  margin-left: 0.9rem;
  outline: 1rem solid $grey-lightest;
  position: relative;
  text-align: center;
  top: 1rem;
  width: 1rem;
  z-index: 1;
  @include md {
    height: 1rem;
    margin-left: calc(5% - 0.5rem);
    order: 1;
    outline: 1rem solid $grey-lightest;
    top: 1rem;
    width: 1rem;
    will-change: transform;

    .CardTimeline-block:last-child & {
      outline: 1rem solid $grey-lighter;
    }

    // .CardTimeline-block:nth-child(even) & {
    //   margin-right: calc(5% - 1.5rem);
    // }
  }

  > .Icon {
    font-size: $h4;
    height: 1.5rem;
    width: 1.5rem;
  }
}

.CardTimeline {
  margin-left: 1rem;
  overflow: visible;
  padding-bottom: 3rem;
  position: relative;
  @include md {
    flex-grow: 0; // prevent element from growing inside its parent
    margin-left: 0;
    width: 45%;
    will-change: transform;
  }

  > .CardTimeline-date {
    font-size: $h3;
    margin-left: 1rem;
    text-align: left;
    @include md {
      margin-top: 0.5rem;
      text-align: right;
    }
  }

  .title {
    font-size: $h4;
    margin-left: 1rem;
    @include md {
      left: 120%;
      position: absolute;
      top: 0.5rem;
      width: 100%;

      // .CardTimeline-block:nth-child(even) & {
      //   left: auto;
      //   right: 120%;
      //   text-align: right;
      // }
    }
  }

  > .head {
    padding: 0;
  }

  > .body {
    padding: 0;
  }

  &:hover::before {
    border-color: $main;
  }
}

.CardTimeline-text {
  font-family: inherit;
  line-break: normal;
  white-space: pre-wrap;
}
</style>
