<template>
  <Sect>
    <Wrap id="self-project-progress">
      <!-- <h2>{{ project.name }}</h2>
      <h3>Projekto eiga</h3> -->
      <Timeline :timeline="projectPosts" />
      <Empty v-if="!projectPosts.length">
        Dar nėra jokių naujienų apie projektą
      </Empty>
    </Wrap>
  </Sect>
</template>

<script>
import Timeline from './components/Timeline'
import { getters } from './utils'

export default {
  components: { Timeline },
  props: {
    projectId: Number,
  },
  computed: {
    project() {
      if (this.projectId) return this.query('project', this.projectId)

      return this.query('project', this.$route.params.id)
    },
    projectPosts() {
      return this.query('projectPost', {
        projectId: this.project.id,
      })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#self-project-progress {
  > h3 {
    margin-bottom: 4rem;
    text-align: center;
  }
}
</style>
